import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';


export const projectDetails: WorkInterface = {
    id: "Antariksha",
    title: "Antariksha Sanchar",
    description: ` 
    Experience a detailed fantasy world with classical Indian music and dance, historic artifacts and mythology with a modern techno-spiritual steampunk twist.

    [link=Antariksha Sanchar|http://www.antariksha.in/] is a single player, third person, action-adventure game set in a fantasy world inspired by Indian mythology and culture. 

    The game is being developed by [link=Antariksha Studios|http://www.antariksha.in/] in Unreal 5 with gorgeous visuals and a unique art style. I was part of the team as a gameplay programmer and game designer for about 6 months.

    The project is currently in progress and has been the recipient for the [link=Epic MegaGrants|https://www.unrealengine.com/en-US/megagrants].
        `,
    year: "2021-2022",
    tags: ["Development", "Game Design", "Unreal"],
    image: "/Antariskha.webp",
    roles: ["Gameplay Programmer", "Game Designer"],
    technologies: ["Unreal", "Blueprint"],
    collaborators: ["[link=Antariskha Studios|http://www.antariksha.in/] , [link=Avinash Kumar|https://blog.beatworx.in/2017/06/avinash-kumar-quicksand-studio-on-art-philosophy-and-development-of-video-game-antriksha-sanchar/], [link=Varun Ramanna|https://www.varuns.work/], [link=Chris Solarski|https://solarskistudio.com/about/]"],
    highlightColor: "#60151F",

};

export function Antariksha() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                {/* <img src={projectDetails.image} alt="Blood Speaks" className="a-image" max-width="30px" /> */}
                <div className="a-body">
                    
                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />
                    <br />


                    <div className="a-subtitle">
                        Gameplay WIP videos
                    </div>


                    <br />
                    <br />
                    <YoutubeEmbed embedId="JnkzD2OMV2Y" />
                    <YoutubeEmbed embedId="8z8e5mKw2xU" />


                    
                    

                    <br />

                    <Divider width={100} />
                </div>
            </WorkBase>
        </div>
    );
}