import { Link } from "react-router-dom";
import "../styles/notfound.css";

export function NotFound() {
    return (
        <div className="not-found">
            <div className="not-found-text">
                Page not found
            </div>
            <Link to="/" className="not-found-link">
                Go back
            </Link>
        </div>
    );
}