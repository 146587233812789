import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "FinlandMuseum",
    title: "Museum Installations",
    description: ` 
            This is a collection of museum installations that I have developed for the museums in and around Finland.

            This project is in collaboration with [link=Panu Heikkilä|https://www.fantomatico.org/] from Fantomatico.

            Till now, I have developed three installations for various museums. 

            These installations are generally web-based and use a combination of hardware and software to create an interactive experience for the users.

            I have also collaborated with [link=Alap Parikh|https://www.alapparikh.com/] to create one of the installations.
        `,
    year: "2022-2023",
    tags: ["Web Development", "Museum Installation"],
    image: "/museum.webp",
    roles: ["Gameplay Programmer", "Game Designer"],
    technologies: ["Javascript", "Node", "Hardware"],
    collaborators: ["[link=Panu Heikkilä|https://www.fantomatico.org/], [link=Alap Parikh|https://www.alapparikh.com/]"],
    highlightColor: "#60151F",

};


export function FinlandMuseum() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    <br />
                    <Divider width={100} />
                    <br />
                    <div className="a-subtitle">Exibition #1: Children's Museum</div>
                    <div className="a-plain-para">
                        Museum about the history of schools in Finland.
                    </div>
                    <WorkCarousel
                        data={
                            [
                                { image: "/Museum/Children1.webp", caption: "School's based on location" },
                                { image: "/Museum/Children2.webp", caption: "Interactable Map" },
                                { image: "/Museum/Children3.webp", caption: "Details" },
                                { image: "/Museum/Children4.webp", caption: "Timeline view" },
                            ]
                        } />

                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />
                    <br />
                    <div className="a-subtitle">Exibition #2: Terranova Museum</div>
                    <div className="a-plain-para">
                        An exibition about wildlife and nature.
                    </div>
                    <WorkCarousel
                        data={
                            [
                                { image: "/museum.webp", caption: "Landing page" },
                                { image: "/Museum/Terra2.webp", caption: "Detail page" },
                            ]
                        } />

                    <br />
                    <br />
                    <br />

                    <Divider width={100} />
                    <br />
                    <br />
                        

                    <div className="a-subtitle">Exibition #3: History Museum</div>
                    <div className="a-plain-para">
                        A timeline of the historic events.
                    </div>
                    <WorkCarousel
                        data={
                            [
                                { image: "/Museum/history1.webp", caption: "Timeline" },
                                { image: "/Museum/history2.webp", caption: "Timeline" },
                            ]
                        } />

                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />
                    <br />
                </div>
            </WorkBase>
        </div>
    );
}