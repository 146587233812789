import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import { WorkCarousel } from '../components/WorkCarousel';
import GameLink from '../components/GameLink';


export const projectDetails: WorkInterface = {
    id: "Metrics",
    title: "Metrics",
    description: ` 
           Measure everything. 

           My partner, [link=Soumyadeep Mukherjee|https://www.soumyadeep.in/], and I are obsessed with collecting data. Hence, we made metrics together. 
          
           Metrics is an attempt to create a platform to measure things in our lives and help visualize them. 
           It is a combination of a telegram bot for data entry and a web app for visualization.

          Check it out!
          [link=How is Charu?|https://metrics.charutak.xyz/]      
          [link=How is Soumyadeep?|https://metrics.soumyadeep.in/]

          It is also an ever improving [link=open source project|https://github.com/thebayesianconspiracy/FxLifeSheet], so feel free to contribute.
          This project was heavily inspired (forked) by [link=KrauseFx|https://howisfelix.today/?].

        `,
    year: "2023",
    tags: ["Product", "Web Dev", "Design", "Data Visualization"],
    image: "/metrics.webp",
    roles: ["Programmer", "Designer"],
    technologies: ["Rust", "Typescript", "Postgress", "React", "D3"],
    collaborators: ["[link=Soumyadeep Mukherjee|https://blog.soumyadeep.in/]"],
    highlightColor: "#60151F",

};


export function Metrics() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    <br />
                    <br />
                    <GameLink gameLink='https://metrics.charutak.xyz' gameName="" gameImage="/bubbleBlitz.webp" width={0}
                        color="000000" hoverColor='213310' overrideText='How is Charu?' />

                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />

                    <div className="a-subtitle">
                        Metrics telegram bot
                    </div>
                    <br />
                    <div className="a-row-div">
                        <img src="/Metrics/metericsapp1.webp" alt="Metrics" className="a-image-row" />
                        <br />
                        <img src="/Metrics/metericsapp2.webp" alt="Metrics" className="a-image-row" />
                        <br />
                        <img src="/Metrics/metericsapp3.webp" alt="Metrics" className="a-image-row" />
                    </div>

                    <Divider width={100} />
                    <br />

                    <div className="a-subtitle">
                        Metrics visualization
                    </div>
                    <br />
                    <br />
                    <WorkCarousel
                        data={
                            [
                                { image: "/Metrics/metericsviz1.webp", caption: "Visualization" },
                                { image: "/Metrics/metericsviz2.webp", caption: "Visualization" },
                                { image: "/Metrics/metericsviz3.webp", caption: "Visualization" },
                                { image: "/Metrics/metericsviz4.webp", caption: "Visualization" },
                            ]
                        } />

                    <Divider width={100} />

                    <div className="a-plain-para">
                        Check out the data visualization.
                        <br />
                    </div>
                    <br />
                    <GameLink gameLink='https://metrics.soumyadeep.in' gameName="" gameImage="/bubbleBlitz.webp" width={0}
                        color="000000" hoverColor='213310' overrideText='How is Soumyadeep?' />
                    <br />
                    <br />

                </div>


            </WorkBase>
        </div>
    );
}