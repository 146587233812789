import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import GameLink from '../components/GameLink';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "mindlessRunner",
    title: "Mindless Runner",
    description: ` 
        An intense endless runner-puzzle hybrid. A gruesome test of focus, dexterity and patience. 

        Mindless runner was created for the 2019 community game jam.
        The theme of the jam was "The game is a liar".

        The game uses the [link=stroop effect|https://en.wikipedia.org/wiki/Stroop_effect] to mess with the player's mind. The colors of the text and the text itself are different. The player has to deduce the correct answer to navigate the ship through the levels while avoiding obstacles.

        This game was made in a extremely fun filled week with the amazing [link=Varun Ramanna|https://www.varuns.work/] and [link=Devanshu Tak|https://www.instagram.com/devanshutak25/].

        `,
    year: "2019",
    tags: ["Development", "Game Design", "Game Jam", "Unity"],
    image: "/mindlessRunner.webp",
    roles: ["Gameplay Programmer", "Game Designer"],
    technologies: ["Unity", "C#", "WebGL"],
    collaborators: ["[link=Varun Ramanna|https://www.varuns.work/], [link=Devanshu Tak|https://www.instagram.com/devanshutak25/]"],
    highlightColor: "#60151F",

};


export function MindlessRunner() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    <br />
                    <br />

                    <GameLink gameLink='https://charutak.itch.io/mindless-runner' gameName="MindlessRunner" gameImage="/mindlessRunner.webp" width={0}
                        color="29241C" hoverColor='9C3A18' />

                    <br />
                    <br />
                    <Divider width={100} />

                    <div className="a-subtitle">
                        Gameplay Images
                    </div>
                    <br />
                    <br />

                    <WorkCarousel
                        data={
                            [
                                { image: "/Runner/runner4.webp", caption: "Gameplay" },
                                { image: "/Runner/runner1.webp", caption: "Main menu" },
                                { image: "/Runner/runner2.webp", caption: "How to" },
                                { image: "/Runner/runner5.webp", caption: "Gameplay" },
                            ]
                        } />

                    <Divider width={100} />
                    <br />
                    <br />

                    <div className="a-subtitle">
                        Gameplay Video
                    </div>

                    <YoutubeEmbed embedId="_jnLgYwl6BI" />

                    <br />
                    <br />
                    <br />

                    <Divider width={100} />

                    <div className="a-plain-para">
                        Hope you play the game and enjoy it. <br />
                    </div>
                    <GameLink gameLink='https://charutak.itch.io/mindless-runner' gameName="MindlessRunner" gameImage="/mindlessRunner.webp" width={0}
                        color="29241C" hoverColor='9C3A18' />
                    <br />
                    <br />
                    <br />

                </div>
            </WorkBase>
        </div>
    );
}