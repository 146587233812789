import { Link } from 'react-router-dom';
import Navigation from "./Navigation";

export function Heading() {
    return (
        <div className="heading">
            <Navigation/>
        </div>
    );
}
