import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BloodSpeaks } from './works/BloodSpeaks'; 
import Work from './pages/Work';
import About from './pages/About';
import './styles/App.css';
import { Heading } from './components/Heading';
import MainMenuBackgroundParticles from './components/Particles';
import { NotFound } from './components/NotFound';
import ScrollToTop from './components/ScrollToTop';
import { Choronoline } from './works/Chronoline';
import { Ladyland } from './works/Ladyland';
import { MindlessRunner } from './works/MindlessRunner';
import { HoneyTwigs } from './works/HoneyTwigs';
import { FutureCaptured } from './works/FutureCaptured';
import { Gamed } from './works/Gamed';
import { BubbleBlitz } from './works/BubbleBlitz';
import { Metrics } from './works/Metrics';
import { HackathonRoom } from './works/HackathonRoom';
import { Antariksha } from './works/Antariksha';
import { CivicGameLab } from './works/CivicGameLab';
import { Econagri } from './works/Econagri';
import { FinlandMuseum } from './works/FinlandMuseum';
import { Incommon } from './works/Incommon';
import { Learn } from './works/Learn';
import { QSlayers } from './works/QSlayers';
import { Tarq } from './works/Tarq';
import { ThePoll } from './works/ThePoll';
import { UnrealFellowship } from './works/UnrealFellowship';
import Privacy from './pages/privacy';

function App() {
  return (
    <div className="Main">
      <Router>
        <ScrollToTop />
        <Heading />
        <div className="App">
        <Routes>
          <Route path="/" element={<Work/>} />  
          <Route path="/chronoline/privacy" element={<Privacy />} /> 
          <Route path="/work/bloodspeaks" element={<BloodSpeaks />} /> 
          <Route path="/work/chronoline" element={<Choronoline />} /> 
          <Route path="/work/bubbleblitz" element={<BubbleBlitz />} /> 
          <Route path="/work/gamed" element={<Gamed />} /> 
          <Route path="/work/futurecapture" element={<FutureCaptured />} /> 
          <Route path="/work/honeytwigs" element={<HoneyTwigs />} /> 
          <Route path="/work/mindlessrunner" element={<MindlessRunner />} /> 
          <Route path="/work/ladyland" element={<Ladyland />} />
          <Route path='/work/metrics' element={<Metrics />} />
          <Route path='/work/HackathonRoom' element={<HackathonRoom />} />
          <Route path='/work/CivicGameLab' element={<CivicGameLab />} />
          <Route path='/work/Synesthesia' element={<UnrealFellowship />} />
          <Route path='/work/Tarq' element={<Tarq />} />
          <Route path='/work/ThePoll' element={<ThePoll />} />
          <Route path='/work/Learn' element={<Learn />} />
          <Route path='/work/Incommon' element={<Incommon />} />
          <Route path='/work/FinlandMuseum' element={<FinlandMuseum />} />
          <Route path='/work/Econagri' element={<Econagri />} />
          <Route path='/work/QSlayers' element={<QSlayers />} />
          <Route path="/work/antariksha" element={<Antariksha />} />

          <Route path="/work" element={<Work />} />
          <Route path="/about" element={<About />} />
          <Route path='*' element={<NotFound />}/>

        </Routes>
        </div>
      <MainMenuBackgroundParticles />
      </Router>
    </div>
  );
}

export default App;
