import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';


export const projectDetails: WorkInterface = {
    id: "HackathonRoom",
    title: "Room Configurator",
    description: ` 
            Room configurator is a tool that allows you to automatically generate a room in Blender based on a natural language description. This project was developed as a part of the [link=Generative AI Hackathon|https://nirantk.com/deephackdemos/]. 

            While setting up a 3D scene, it is often a tedious task to set up the scene and place all the objects in it one by one. This tool allows you to generate a room based on a natural language description.

            This gives the user a good starting point to start building their scene. Reducing the time spent on setting up the scene.

            The project was developed with [link=Yash Pandya|https://www.linkedin.com/in/yash-pandya96/?originalSubdomain=in] and [link=Devanshu Tak|https://www.instagram.com/devanshutak25/].
        `,
    year: "2023",
    tags: ["Tool", "3D", "AI", "Development", "Blender", "Hackathon"],
    image: "/blender.webp",
    roles: ["Developer"],
    technologies: ["Blender", "Python", "ChatGPT"],
    collaborators: ["[link=Yash Pandya|https://www.linkedin.com/in/yash-pandya96/?originalSubdomain=in], [link=Devanshu Tak|https://www.instagram.com/devanshutak25/]"],
    highlightColor: "#60151F",

};

export function HackathonRoom() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    
                    <br />
                    <br />
                    <Divider width={100} />
                    
                    <br />

                    <div className="a-subtitle">
                        Video
                    </div>
                    <div className="a-plain-para">
                        The project used ChatGPT to select and place the assets from a database of 3D assets. The program would find a reasonable location and rotation for the object according to the room and the object bounds. 
                        The ChatGPT would then return a custom markup language that would be parsed by the python code in Blender to generate the room.
                        <br/>
                        <br/>
                        Check out the video below to see the project in action.
                    </div>

                    <YoutubeEmbed embedId="rI1s1VJJ_34" />
                        <br/>
                        <br/>
                        <br/>
                        <Divider width={100} />
                        <br/>
                        <br/>
                </div>
            </WorkBase>
        </div>
    );
}