import '../styles/about.css';
import { ContactIcons } from '../components/contacticons';

function About() {

    return (
        <div className='about-background'>
            <div className='about-aboutme'>
                <img src="/charu.webp" alt="Charu" className="about-image" />
                <div className='about-text'>
                    Hi there! I'm Charu Tak, an independent developer.
                    <br/> <br/>
                    For the past five years, I've been freelancing and have had the pleasure of working on a variety of games. My portfolio also includes interactive experiences, products, tools, and more.
                    <br />
                    <br />
                    I am especially proud of the range of <a className='link' href="https://charutak.itch.io"> indie games  </a> I have made over the years. 

                    <br/>
                    <br/>

                    Always happy to talk about new projects, ideas or just chat, so don't hesitate to reach out! <br/> <br/>
                    Currently based in Bangalore, India.
                    <br />
                    <br />


                </div>
            </div>
            <div className='about-contact'>
            <ContactIcons />
            </div>
        </div>);
}

export default About;