import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "futurecapture",
    title: "Futurecapture",
    description: ` 
    Captured is a participatory installation, which captures your face and creates a new identity for you in a collective scenario taking place in virtual world.

    Futurecapture is a new iteration of the installation Captured, which I helped develop. 
    It imagines the participants in a futuristic scenario in a variety of avatars.

    The humans are imagined in multiple forms, Aurora, Midnight, Foliage, Fog, Magma and Snow. These created avatars then walk in an endless loop with each other.

        `,
    year: "2022",
    tags: ["Development", "Museum Installation", "Unity"],
    image: "/captured2.webp",
    roles: ["Gameplay Programmer"],
    technologies: ["Unity", "C#"],
    collaborators: ["[link=Hannah Haaslahti|http://www.hannahaaslahti.net/about/], [link=Mike Robbins|https://www.highroadstories.com/about ], [link=Alap Parikh|https://www.alapparikh.com/]"],
    highlightColor: "#60151F",
};


export function FutureCaptured() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    <Divider width={100} />
                    <div className="a-plain-para">
                        Some behind the scenes images:
                        <br />
                    </div>

                    <WorkCarousel
                        data={
                            [
                                { image: "/Future/Future1.webp", caption: "Avatar Creation" },
                                { image: "/Future/Future2.webp", caption: "Avatar Setup" },
                                { image: "/Future/Future3.webp", caption: "Avatars Walking" },
                            ]
                        } />
                    <br />
                    <br />
                    <Divider width={100} />
                    <div className="a-plain-para">
                        Behind the scene video:
                        <br />
                    </div>

                    <YoutubeEmbed embedId="u-bZL5ZEgbI" />
                    <br />
                    <Divider width={100} />

                </div>
            </WorkBase>
        </div>
    );
}