import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../styles/nav.css';
import { ReactComponent as Hamburger } from '../images/hamburger.svg';
import MobileNav from './MobileNav';

interface NavItemProps {
  title: string;
  link: string;
  closeMenu: () => void; // New callback prop
}
 const NavItem: React.FC<NavItemProps> = ({ title, link, closeMenu }) => {
  const location = useLocation();
  const isActive = location.pathname === link || (link === '/work' && location.pathname === '/');

  const handleClick = () => {
    closeMenu();
  };

  return (
    <li>
      <NavLink
        to={link}
        className={`nav-link ${isActive ? 'nav-link-active' : ''}`}
        onClick={handleClick}
      >
        {title}
      </NavLink>
    </li>
  );
};

const Navigation: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        closeMenu();
        document.body.style.overflow = 'auto';
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav>
      <button className="menu-icon" onClick={toggleMenu}>
        <Hamburger className="hamburger-icon" />
      </button>
      <ul className={`nav-list`}>
        {/* <NavItem title="Blogs" link="/blog" closeMenu={closeMenu} /> */}
        <NavItem title="Work" link="/work" closeMenu={closeMenu} />
        <NavItem title="About Me" link="/about" closeMenu={closeMenu} />
      </ul>
      {isMenuOpen && <MobileNav setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />}
    </nav>
  );
};

export default Navigation;
