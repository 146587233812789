import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import { Divider } from '../components/Divider';
import GameLink from '../components/GameLink';
import YoutubeEmbed from '../components/Youtube';


export const projectDetails: WorkInterface = {
    id: "Gamed",
    title: "Game'D",
    description: ` 
            Game'D: 6 games in 6 months. A series of games created by me and my sister, [link=Divya Tak|divyatak.art].
           
            We also collaborated with [link=Devanshu Tak|https://www.instagram.com/devanshutak25/] and [link=Varun Ramanna|https://www.varuns.work/] for one of the games.

            This was a really fun project we took up in the January of 2020. We wanted to create a series of games, each with a different theme and genre. We wanted to focus on shipping games and along the way figure out our style and what we enjoyed making the most.

            Along side the games, we also created a series of videos documenting our process and learnings. You can find the videos [link=here|https://www.youtube.com/watch?v=YZET-0OZTmU&list=PLEJFDb1V8u0ArsxsfOm1o1xH7kq7WsKYw].

            Hope you play and enjoy the games!
        `,
    year: "2020",
    tags: ["Development", "Game Design", "Unity", "WebGL"],
    image: "/Gamed/AIQuit.webp",
    roles: ["Gameplay Programmer", "Game Designer"],
    technologies: ["Unity", "GarageBand", "C#", "WebGL"],
    collaborators: ["All Games With [link=Divya Tak|divyatak.art], AI Quit With [link=Devanshu Tak|https://www.instagram.com/devanshutak25/] & [link=Varun Ramanna|https://www.varuns.work/]"],
    highlightColor: "#60151F",

};


export function Gamed() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">

                    <Divider width={80} />
                    <br />

                    <br />
                    <div className='a-row-div'>
                        <div className="a-plain-para">
                            <div className='a-subtitle'>
                                January: Mark
                            </div>
                            <br />
                            <i>Mark is a game about bouncing from wall to wall, collecting keys, and avoiding the enemies!</i>
                            <br />
                            <br />
                            We wanted to start off with something simple. We decided to keep the art style minimal and focus on a simple mechanic.
                            <br />
                            <br />
                            This was the first time we were designing levels and we learned a lot which helped us in the games to come.
                            <br />
                            <br />
                            I also ended up composing the sound track to the game!
                            <br />
                            <br />
                        </div>
                        <div className='a-column-div'>
                            <img src="/Gamed/Mark.webp" alt="Mark" className="a-image" style={{ width: '30rem' }} />
                            <GameLink gameLink='https://charutak.itch.io/mark' gameName="Mark" gameImage="/Gamed/Mark.webp" width={11} color="0E0814" hoverColor='EC6A9F' />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <Divider width={80} />
                    <div className='a-row-div a-reverse'>
                        <div className="a-plain-para">
                            <div className='a-subtitle'>
                                Feburary: Ruh and the Glow
                            </div>
                            <br />
                            <i> Ruh and the Glow is a jigsaw puzzle, exploring Ruh's journey through the forest. </i>
                            <br />
                            <br />
                            After finishing our first game, we both wanted to try to focus on the things that we payed less attention to in the first game. We both decided to spend more time on art and music for this game. We also wanted it to have a cozy feel.
                            <br />
                            <br />
                            This time I ended up composing multiple tracks for the game!
                            <br />
                            <br />
                        </div>
                        <div className='a-column-div'>
                            <img src="/Gamed/Ruh.webp" alt="Ruh" className="a-image" style={{ width: '30rem' }} />
                            <GameLink gameLink='https://charutak.itch.io/ruh' gameName="Ruh" gameImage="/Gamed/Ruh.webp" width={10}
                                color="406563" hoverColor='2E3D6F' />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <Divider width={80} />
                    <div className='a-row-div'>
                        <div className="a-plain-para">
                            <div className='a-subtitle'>
                                March: Boneyard
                            </div>
                            <br />
                            <i> Boneyard is a hack and slash game where you try to survive while fighting off waves of enemies. </i>
                            <br />
                            <br />
                            After finishing a couple of non-violent games, we decided to dip our toes into the world of action games. We wanted to create a game that was fast paced and had a lot of action. We also wanted to intentionally go for an art style that was different from our general style.
                            <br />
                            <br />
                            As much as we enjoyed making this game, we realised that our style lies more in making less action oriented games. As the month came to an end we also noticed that this time we took on a lot more than what we could handle in a month.
                            <br />
                            <br />
                        </div>
                        <div className='a-column-div'>
                            <img src="/Gamed/Boneyard.webp" alt="Boneyard" className="a-image" style={{ width: '30rem' }} />
                            <GameLink gameLink='https://charutak.itch.io/boneyard' gameName="Boneyard" gameImage="/Gamed/Boneyard.webp" width={25}
                                color="222221" hoverColor='D32D21' />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <Divider width={80} />
                    <div className='a-row-div a-reverse'>
                        <div className="a-plain-para">
                            <div className='a-subtitle'>
                                April: Misplaced
                            </div>
                            <br />
                            <i>Misplaced is a text based escape room puzzle. Solve puzzles to get out of the room.</i>
                            <br />
                            <br />
                            After finishing an intense month of making Boneyard, we wanted to take a step back and make something more chill. We put the restriction of having no art in the game and decided to focus on the mechanics. We ended up settling on making a text based game.
                            <br />
                            <br />
                            Though we had a lot of restrictions on this game, we ended up having a lot of fun making it. It also turned out to be our favourite game of the series. A sentiment that was shared by a lot of people who played it.
                            <br />
                            <br />
                        </div>
                        <div className='a-column-div'>
                            <img src="/Gamed/Misplaced.webp" alt="misplaced" className="a-image" style={{ width: '30rem' }} />
                            <GameLink gameLink='https://charutak.itch.io/misplaced' gameName="Misplaced" gameImage="/Gamed/Misplaced.webp" width={25}
                                color="000000" hoverColor='CA463F' />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <Divider width={80} />
                    <div className='a-row-div'>
                        <div className="a-plain-para">
                            <div className='a-subtitle'>
                                April: AI Quit
                            </div>
                            <br />
                            <i>
                                AI Quit is about a robot sneaking out of a factory avoiding all the guards.
                            </i>
                            <br />
                            <br />
                            After finishing 4 games. We decided to collaborate with Devanshu Tak (our brother) and Varun Ramanna (our amazing friend, he really is the best) to make a game. Since we had a bunch of 3D artists on the team, we decided to make a 3D game. We also wanted to make a game that was more cute and funny.
                            <br />
                            <br />
                            This month was a lot of fun and I also learned a lot about optimiztion techniques for 3D games, specially since it was in WebGL.
                            <br />
                            <br />
                        </div>
                        <div className='a-column-div'>
                            <img src="/Gamed/AIQuit.webp" alt="AI Quit" className="a-image" style={{ width: '30rem' }} />
                            <GameLink gameLink='https://charutak.itch.io/ai-quit' gameName="AI Quit" gameImage="/Gamed/AIQuit.webp" width={20}
                                color="717578" hoverColor='DA654F'
                            />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <Divider width={80} />
                    <div className='a-row-div a-reverse'>
                        <div className="a-plain-para">
                            <div className='a-subtitle'>
                                June: Proximity
                            </div>
                            <br />
                            <i> Proximity is about placing colors in the correct order and filling the board. </i>
                            <br />
                            <br />
                            As we reached the end of the series, we realised that until now we haven't made a game with a lot of levels. So this time, we decided we will take a mechanic and make a lot of levels around it. We also wanted to make a game that was more chill and relaxing.
                            <br />
                            <br />
                            And that's it, this was the last game of the series. We had a lot of fun making these games and we learned a lot along the way. We also learned a lot about our style and what we enjoy making the most.
                            <br />
                            <br />
                        </div>
                        <div className='a-column-div'>
                            <img src="/Gamed/Proximity.webp" alt="Proximity" className="a-image" style={{ width: '30rem' }} />
                            <GameLink gameLink='https://charutak.itch.io/proximity' gameName="Proximity" gameImage="/Gamed/Proximity.webp" width={20}
                                color="413E49" hoverColor='E87D7A'
                            />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <Divider width={80} />

                    <div className="a-plain-para">
                        We also created a series of videos documenting our process and learnings.
                        <br />
                        <br />
                        <br />
                        <div className='a-row-div'>
                            <YoutubeEmbed embedId="YZET-0OZTmU" />
                            <YoutubeEmbed embedId="sdWQ7PwXZSE" />
                            <YoutubeEmbed embedId="kq55Od_mA_8" />
                        </div>
                        <div className='a-row-div'>
                            <YoutubeEmbed embedId="g56fjmsIIZg" />
                            <YoutubeEmbed embedId="bZy-VKiIzUI" />
                            <YoutubeEmbed embedId="L92W7zcnLGk" />
                        </div>
                    </div>


                    <Divider width={80} />
                </div>

            </WorkBase >
        </div >
    );
}