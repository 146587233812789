import React, { useState } from 'react';
import '../styles/game-link.css';

interface GameLinkProps {
    gameLink: string;
    gameName: string;
    gameImage: string;
    width: number;
    color: string;
    hoverColor?: string;
    overrideText?: string;
}

function GameLink({gameLink, gameName, gameImage, width, color, hoverColor, overrideText}: GameLinkProps) {
    const [isHovered, setIsHovered] = useState(false);

    const linkStyle = {
        // width: `${width}rem`,
        backgroundColor: isHovered && hoverColor ? `#${hoverColor}` : `#${color}`
    };

    return (
        <div className="game-link" >
            <a href={gameLink} target="_blank" rel="noreferrer" className="game-link-link">
                <div className="game-link-text" style={linkStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    {overrideText ? overrideText : "Play "} {gameName}
                </div>
            </a>
        </div>
    );
}

export default GameLink;
