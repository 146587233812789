import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "ladyland",
    title: "Ladyland",
    description: ` 
    The nation of Ladyland exists in dreams. One needs to earn one’s way around here by learning to build an equal, sustainable and free society. Ladyland is the manifestation of hope in protopia; the only one of it’s kind.

    The project was about creating a vertical slice for a narrrative driven game based on Rokeya Hussain’s iconic story from 1907, Sultana’s Dream. 

    I worked on this project with the amazing [link=Varun Ramanna|https://www.varuns.work/]. This project was made and produced by Hina Saiyada from the [link=ODDJOINT|https://www.oddjoint.com/]. 

    The project was made in Unreal 5, and has been selected for [link=Epic MegaGrants|https://www.oddjoint.com/].
        `,
    year: "2021",
    tags: ["Development", "Game Design", "Environment", "Unreal"],
    image: "/Ladyland/ladyland3.webp",
    roles: ["Gameplay Programmer", "Game Designer", "Environment Artist"],
    technologies: ["Unreal", "Blueprint"],
    collaborators: ["[link=ODDJOINT|https://www.oddjoint.com/] and [link=Varun Ramanna|https://www.varuns.work/]"],
    highlightColor: "#60151F",

};

export function Ladyland() {

    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">

                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />
                    <br />

                    <div className="a-subtitle">
                        Gameplay Images
                    </div>

                    <br />
                    <br />
                    <WorkCarousel
                        data={
                            [
                                { image: "/Ladyland/ladyland1.webp", caption: "Gameplay" },
                                { image: "/Ladyland/ladyland2.webp", caption: "Gameplay" },
                                { image: "/Ladyland/ladyland3.webp", caption: "Gameplay" },
                                { image: "/Ladyland/ladyland4.webp", caption: "Gameplay" },
                                { image: "/Ladyland/ladyland5.webp", caption: "Gameplay" },
                            ]
                        } />

                    <br />
                    <br />
                    <br />
                    <div className="a-subtitle">
                        Gameplay Video
                    </div>

                    <br />
                    <br />

                    <YoutubeEmbed embedId="wuKJZH-BOdw" />
                
                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />
                    <br />
                    <br />

                </div>
            </WorkBase>
        </div>
    );
}