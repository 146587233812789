import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import GameLink from '../components/GameLink';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "Learn",
    title: "Explore",
    description: ` 
            What you don't know about the things you know.

            Learn is an attempt to create a platform that help you find the gaps in your knowledge and help you fill them. It is an AI powered node graph that helps you go deep into any topic you want to learn about. There is no limit, it expands as much as you want it to.

            Check it out [link=here|https://learn.charutak.xyz/].

            The project is currently in development, so keep an eye out for updates.
        `,
    year: "2023",
    tags: ["Solo Product", "Web Dev", "Product", "AI"],
    image: "/Learn/learncoffee.webp",
    roles: ["Creator"],
    technologies: ["Node", "Javascript","AI"],
    collaborators: [""],
    highlightColor: "#60151F",

};


export function Learn() {
    return (
        <div>
            <WorkBase project={projectDetails} >
            <div className="a-body">
                    <br />
                    <br />
                    <GameLink gameLink='https://learn.charutak.xyz' gameName="Learn" gameImage="/bubbleBlitz.webp" width={0}
                        color="111111" hoverColor='EF8636' overrideText='Try ' />

                    <br />
                    <br />
                    <Divider width={100} />

                    <YoutubeEmbed embedId="G4aBf2PvR1c" />

                    <br />
                    <br />
                    <div className="a-subtitle">
                        How it looks:
                    </div>
                    <br />
                    <br />
                    <br />

                    <WorkCarousel
                        data={
                            [
                                { image: "/Learn/learnmainpage.webp", caption: "Explore Anything" },
                                { image: "/Learn/learncoffee.webp", caption: "Coffee" },
                            ]
                        } />

                    <Divider width={100} />

                    <div className="a-plain-para">
                        Hope you try the product and decide what you want to learn next. <br />
                    </div>
                    <GameLink gameLink='https://learn.charutak.xyz' gameName="Learn" gameImage="/bubbleBlitz.webp" width={0}
                        color="111111" hoverColor='EF8636' overrideText='Try ' />
                    <br />
                    <br />
                    <br />
                </div>
            </WorkBase>
        </div>
    );
}