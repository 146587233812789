import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import GameLink from '../components/GameLink';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "honeytwigs",
    title: "Honey Twigs",
    description: ` 
    Something important is coming up in 3 days and Honey Twigs has been busy preparing for it to be a success! 

    Enjoy the days leading up to the main event as well as the finale itself in this short and cute visual novel where you play as an employee at Honey Twigs- a tiny magical cafe conjoined with a thriving nursery. 
    
    A little game that will leave you warm and fuzzy, like a hot cup of tea in a breezy, spring evening. 
        
    This cute and cozy visual novel was made by me and [link=Monica|https://twitter.com/crayoncatArt]. It was also part of the [link=Indie Bundle for Palestinian Aid|https://itch.io/b/902/indie-bundle-for-palestinian-aid]!
        `,
    year: "2020",
    tags: ["Development", "Game Design", "Unity"],
    image: "/honeyTwigs.webp",
    roles: ["Gameplay Programmer", "Game Designer"],
    technologies: ["Unity", "C#", "WebGL"],
    collaborators: ["[link=Monica|https://twitter.com/crayoncatArt]"],
    highlightColor: "#60151F",

};


export function HoneyTwigs() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    <br />
                    <br />
                    <GameLink gameLink='https://charutak.itch.io/honeytwigs' gameName="HoneyTwigs" gameImage="/honeytwigs.webp" width={0}
                        color="221B1A" hoverColor='F09EAF' />

                    <br />
                    <br />
                    <Divider width={100} />

                    <div className="a-subtitle">
                        Gameplay
                    </div>
                    <br />
                    <br />

                    <WorkCarousel
                        data={
                            [
                                { image: "/Honeytwigs/HoneyTwigs1.webp", caption: "Main menu" },
                                { image: "/Honeytwigs/HoneyTwigs2.webp", caption: "Credits" },
                                { image: "/Honeytwigs/HoneyTwigs4.webp", caption: "Gameplay" },
                                { image: "/Honeytwigs/HoneyTwigs5.webp", caption: "Gameplay" },
                                { image: "/Honeytwigs/HoneyTwigs6.webp", caption: "Gameplay" },
                            ]
                        } />

                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <div className="a-plain-para">
                        Hope you play the game and enjoy it. <br />
                    </div>
                    <GameLink gameLink='https://charutak.itch.io/honeytwigs' gameName="HoneyTwigs" gameImage="/honeytwigs.webp" width={0}
                        color="221B1A" hoverColor='F09EAF' />
                    <br />
                    <br />
                    <br />
                </div>


            </WorkBase>
        </div>
    );
}