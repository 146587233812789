import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "qslayers",
    title: "QSlayers",
    description: ` 
        QSlayers is a 2D, turn-based strategy game where you defeat monsters by answering questions. This is an educational game that aims to make the process of practicing questions fun and engaging.

        The game involves a classic rouge-like dungeon crawler experience with a twist. To attack monsters, you have to answer questions correctly or else you will take damage. 
        
        The game is designed to be played in short bursts and is perfect for a quick study break.

        The game was developed by [link=Chimera Studios|https://www.linkedin.com/in/vikram-bhambhu-a449b3244/?originalSubdomain=in]. I worked as a developer on the project, with [link=Monica|https://twitter.com/crayoncatArt] as the artist.
        `,
    year: "2022",
    tags: ["Development", " Game Design"],
    image: "/QSlayers/Q2.webp",
    roles: ["Gameplay Programmer", "Game Designer"],
    technologies: ["Unity", "Javascript", "AWS", "Firebase", "WebGL"],
    collaborators: ["[link=Chimera Studios|https://www.linkedin.com/in/vikram-bhambhu-a449b3244/?originalSubdomain=in], [link=Monica|https://twitter.com/crayoncatArt]"],
    highlightColor: "#60151F",

};


export function QSlayers() {
    return (
        <div>
            <WorkBase project={projectDetails} >

                <div className="a-body">
                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />

                    <div className="a-subtitle">
                        Game Characters
                    </div>
                    <br />
                    <div className="a-row-div">
                        <img src="/QSlayers/Q3.webp" alt="q1" className="a-image-row" />
                        <br />
                        <img src="/QSlayers/Q4.webp" alt="q2" className="a-image-row" />
                        <br />
                    </div>

                    <Divider width={100} />
                    <br />

                    <div className="a-subtitle">
                        Game Enemies
                    </div>
                    <br />
                    <div className="a-row-div">
                        <img src="/QSlayers/Q5.webp" alt="q5" className="a-image-row" />
                        <br />
                        <img src="/QSlayers/Q6.webp" alt="q6" className="a-image-row" />
                        <br />
                        <img src="/QSlayers/Q7.webp" alt="q7" className="a-image-row" />
                        <br />
                    </div>

                    <div className="a-row-div">
                        <img src="/QSlayers/Q8.webp" alt="q8" className="a-image-row" />
                        <br />
                        <img src="/QSlayers/Q9.webp" alt="q9" className="a-image-row" />
                        <br />
                        <img src="/QSlayers/Q10.webp" alt="q10" className="a-image-row" />
                        <br />
                    </div> 

                    <Divider width={100} />
                    <br />

                    <div className="a-subtitle">
                        Game Screenshots
                    </div>
                    <br />
                    <br />
                    <WorkCarousel
                        data={
                            [
                                { image: "/QSlayers/Q1.webp", caption: "Gameplay" },
                                { image: "/QSlayers/Q2.webp", caption: "Gameplay" },
                                { image: "/QSlayers.webp", caption: "MainMenu" },
                            ]
                        } />

                    <Divider width={100} />

                    <br />
                    </div>
            </WorkBase>
        </div>
    );
}