import '../styles/contact.css'
import { Divider } from './Divider'

export function ContactIcons() {
    return (
        <div className='contact'>

            <br />
            <br />

            <div className='contact-heading'>
                Always happy to chat!
            </div>


            <br />
            <div className="contact-icons">
                <a href="mailto:contactcharutak@gmail.com" target="_blank" rel="noreferrer" className='contact-text'>
                    mail
                </a>
                <a href='https://charutak.itch.io/' target="_blank" rel="noreferrer" className='contact-text'>
                    itch.io
                </a>

                <a href="https://www.linkedin.com/in/charu-tak/" target="_blank" rel="noreferrer" className='contact-text'>
                    linkedin
                </a>
                <a href="https://www.github.com/charutak" target="_blank" rel="noreferrer" className='contact-text'>
                    github
                </a>
                <a href="https://www.twitter.com/charu_tak" target="_blank" rel="noreferrer" className='contact-text'>
                    twitter
                </a>
                <a href="https://www.instagram.com/charutak" target="_blank" rel="noreferrer" className='contact-text'>
                    instagram
                </a>
            </div>
        </div>
    );
}