import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "thePoll",
    title: "The Poll",
    description: ` 
    
    Digitization of the board game ‘The Poll’ by Civic Games Lab. The game is a multiplayer turn-based strategy game that simulates the Indian General Elections.

    The Poll is a game that puts regular people in charge of the affairs of a political party. In the game, players draft the manifesto, argue and debate the policies, plan the campaign strategy, while managing the resources.

    This project was a part of my ongoing collaboration with [link=Abeer Kapoor |https://civicgameslab.com] from the Civic Games Lab.
        `,
    year: "2022",
    tags: ["Development", "Multiplayer", "Game", "Unity"],
    image: "/poll.webp",
    roles: ["Gameplay Programmer"],
    technologies: ["Unity", "C#", "Firebase", "WebGL"],
    collaborators: ["[link=Civic Games Lab (Abeer Kapoor)|https://civicgameslab.com]"],
    highlightColor: "#60151F",

};


export function ThePoll() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />
                    <br />


                    <div className="a-subtitle">
                        'The Poll' - The digital version (Behind the scenes)
                    </div>
                    <br />
                    <br />

                    <WorkCarousel
                        data={
                            [
                                { image: "/poll/pollb6.webp", caption: "Main menu" },
                                { image: "/poll/pollb5.webp", caption: "Main menu" },
                                { image: "/poll/pollb1.webp", caption: "Gameplay WIP sketch" },
                                { image: "/poll/pollb2.webp", caption: "Gameplay mockup" },
                                { image: "/poll/pollb3.webp", caption: "Gameplay mockup" },
                                { image: "/poll/pollb4.webp", caption: "Gameplay mockup" },
                            ]
                        } />

                    <br />
                    <br />

                    <Divider width={100} />
                    <br />
                    <br />

                    <div className="a-subtitle">
                        'The Poll' - The board game.
                    </div>

                    <br />
                    <br />

                    <WorkCarousel
                        data={
                            [
                                { image: "/poll/poll2.webp", caption: "Gameplay" },
                                { image: "/poll/poll3.webp", caption: "Gameplay" },
                                { image: "/poll/poll4.webp", caption: "Boardgame" },
                                { image: "/poll.webp", caption: "Boardgame" },
                            ]
                        } />

                    <br />
                    <br />
                    <br />
                </div>



            </WorkBase>
        </div>
    );
}