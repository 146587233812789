import { useCallback } from "react";
import type { Container, Engine } from "tsparticles-engine";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import '../styles/App.css';
import { useLocation } from 'react-router-dom';
import { particleOptionsPerRoute, defaultParticleOptions } from './particleOptions';

const MainMenuBackgroundParticles = () => {
    const location = useLocation();

    const particleOptions = particleOptionsPerRoute[location.pathname] || defaultParticleOptions;

    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
    }, []);

    return (
        <div className="particles">
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={particleOptions}
            />
        </div>
    );
};

export default MainMenuBackgroundParticles;