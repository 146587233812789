import WorkPreview from "../components/WorkPreview";
import { ContactIcons } from "../components/contacticons";
import "../styles/work.css";

import { projectDetails as Chronoline } from "../works/Chronoline";
import { projectDetails as Antariksha } from "../works/Antariksha";
import { projectDetails as BloodSpeaks } from "../works/BloodSpeaks";
import { projectDetails as Ladyland } from "../works/Ladyland";
import { projectDetails as MindlessRunner } from "../works/MindlessRunner";
import { projectDetails as HoneyTwigs } from "../works/HoneyTwigs";
import { projectDetails as FutureCaptured } from "../works/FutureCaptured";
import { projectDetails as Gamed } from "../works/Gamed";
import { projectDetails as BubbleBlitz } from "../works/BubbleBlitz";
import { projectDetails as Econagri } from "../works/Econagri";
import { projectDetails as FinlandMuseum } from "../works/FinlandMuseum";
import { projectDetails as Incommon } from "../works/Incommon";
import { projectDetails as Learn } from "../works/Learn";
import { projectDetails as ThePoll } from "../works/ThePoll";
import { projectDetails as Tarq } from "../works/Tarq";
import { projectDetails as UnrealFellowship } from "../works/UnrealFellowship";
import { projectDetails as QSlayers } from "../works/QSlayers";
import { projectDetails as CivicGameLab } from "../works/CivicGameLab";
import { projectDetails as HackathonRoom } from "../works/HackathonRoom";
import { projectDetails as Metrics } from "../works/Metrics";



function Work() {

    var workPosts: WorkInterface[] = [
        BloodSpeaks,
        Chronoline,
        Gamed,
        Learn,
        Ladyland,
        Metrics,
        Antariksha,
        HoneyTwigs,
        MindlessRunner,
        HackathonRoom,
        UnrealFellowship,
        BubbleBlitz,
        FinlandMuseum,
        Econagri,
        Tarq,
        FutureCaptured,
        Incommon,
        QSlayers,
        ThePoll,
        CivicGameLab,
    ];

    return (
        <div className="project-parent">
            <div className="project-background">
                <div className="project-top">
                    <div className="project-top-description">
                        <div className="horizontal-line" >
                            Hi! I am <span className="highlight-text">Charu Tak.</span>
                        </div>
                        <div className="horizontal-line" >
                            I make <span className="highlight-text"> games</span>, interactive experiences, products and everything in-between.
                        </div>
                        <div className="horizontal-line" >
                            Check out my <span className="highlight-text">work</span> below.
                        </div>
                    </div>
                    <div className="line-divider" />
                    <br />
                    <br />
                </div>
                <div className="project-posts">
                    {workPosts.map((workPost) => (
                        <WorkPreview
                            id={workPost.id}
                            title={workPost.title}
                            description={workPost.description}
                            date={workPost.year}
                            tags={workPost.tags}
                            key={workPost.id}
                            image={workPost.image}
                        />
                    ))}
                </div>
            </div>
            <ContactIcons />
        </div>
    );
}


export interface WorkInterface {
    title: string;
    description: string;
    year: string;
    tags: string[];
    id: string;
    image: string;
    roles?: string[];
    technologies?: string[];
    collaborators?: string[];
    highlightColor?: string;
}

export default Work;


