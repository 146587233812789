import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import GameLink from '../components/GameLink';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "bubbleBlitz",
    title: "Bubble Blitz",
    description: ` 
    Move around to gulp bubbles tinier than you!
    Shrinking reduces size but also is the only way you get points! 

    Bubble Bltiz was a game made for Ludum Dare 44. The theme was "Your life is currency". This was my first game! I completed it in 48 hours and it was a lot of fun.

    I challenged myself to complete all the art, music and programming myself. This was my firt time making art and music! 
    This was definitely a great learning experience and moved my path towards game development.
        `,
    year: "2019",
    tags: ["Solo creator", "Game", "Unity", "Game Jam"],
    image: "/bubbleBlitz.webp",
    roles: ["Creator"],
    technologies: ["Unity", "GarageBand", "Procreate"],
    collaborators: [],
    highlightColor: "#60151F",

};


export function BubbleBlitz() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    <br />
                    <br />
                    <GameLink gameLink='https://charutak.itch.io/bubble-blitz' gameName="Bubble Blitz" gameImage="/bubbleBlitz.webp" width={0}
                        color="744147" hoverColor='BBD258' />

                    <br />
                    <br />
                    <Divider width={100} />

                    <div className="a-subtitle">
                        Gameplay
                    </div>
                    <br />
                    <br />
                    <br />

                    <WorkCarousel
                        data={
                            [
                                { image: "/Bubble/Bubble1.webp", caption: "Gameplay" },
                                { image: "/Bubble/Bubble2.webp", caption: "Gameplay" },
                                { image: "/Bubble/Bubble3.webp", caption: "Gameplay" },
                                { image: "/Bubble/Bubble4.webp", caption: "How to" },
                                { image: "/Bubble/Bubble5.webp", caption: "Main menu" },
                            ]
                        } />

                    <Divider width={100} />

                    <div className="a-plain-para">
                        Hope you play the game and enjoy it. <br />
                    </div>
                    <GameLink gameLink='https://charutak.itch.io/bubble-blitz' gameName="Bubble Blitz" gameImage="/bubbleBlitz.webp" width={0}
                        color="744147" hoverColor='BBD258' />
                    <br />
                    <br />
                    <br />
                </div>
            </WorkBase>
        </div>
    );
}