import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import { WorkCarousel } from '../components/WorkCarousel';
import GameLink from '../components/GameLink';


export const projectDetails: WorkInterface = {
    id: "Econagri",
    title: "Econagri",
    description: ` 
    
    [link=Econagri|https://wingify.earth/game/econagri] challenges you to become a master city planner. 
    It is a tile based city building game where you have to build a city that is both economically and ecologically sustainable. 
    
    Ecogari was developed for the Wingify Foundation by Bakarmax. I was the programmer on the project.

    [link=Wingify Foundation|https://wingifyfoundation.org/] is the CSR arm of [link=Wingify Software Private Limited|https://wingify.com/], where they unite a community of conscientious citizens determined to ensure environment sustainability and combat air pollution and climate change under a common umbrella [link=Wingify.Earth|https://wingify.earth/].
        `,
    year: "2023",
    tags: ["Development", "Game", "Unity"],
    image: "/Econagri.webp",
    roles: ["Gameplay Programmer", "Game Designer"],
    technologies: ["Unity", "C#", "Android", "WebGL"],
    collaborators: ["[link=Bakarmax|https://bakarmax.com/], [link=Wingify|https://wingify.earth/]"],
    highlightColor: "#60151F",

};


export function Econagri() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    <br />
                    <br />
                    <GameLink gameLink='https://wingify.earth/game/econagri' gameName="Econagri" gameImage="/bubbleBlitz.webp" width={0}
                        color="7E563A" hoverColor='6F8C3F' />

                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />
                    <div className="a-subtitle">
                        Econagri gameplay
                    </div>
                    <br />
                    <br />
                    <WorkCarousel
                        data={
                            [
                                { image: "/Eco/Eco10.webp", caption: "Gameplay" },
                                { image: "/Eco/Eco9.webp", caption: "Gameplay" },
                                { image: "/Eco/Eco11.webp", caption: "Guide" },
                                { image: "/Eco/Eco12.webp", caption: "Credits" },
                                { image: "/Eco/Eco13.webp", caption: "Main menu" },
                                { image: "/Eco/Eco15.webp", caption: "Language Choice" },
                            ]
                        } />

                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />
                    <br />

                    <div className="a-subtitle">
                        Gameplay Tiles
                    </div>
                    <br />
                    <div className="a-row-div">
                        <img src="/Eco/Eco1.webp" alt="Metrics" className="a-image-row" />
                        <br />
                        <img src="/Eco/Eco2.webp" alt="Metrics" className="a-image-row" />
                        <br />
                        <img src="/Eco/Eco3.webp" alt="Metrics" className="a-image-row" />
                        <br />
                    </div>
                    <div className="a-row-div">
                        <img src="/Eco/Eco4.webp" alt="Metrics" className="a-image-row" />
                        <br />
                        <img src="/Eco/Eco5.webp" alt="Metrics" className="a-image-row" />
                        <br />
                        <img src="/Eco/Eco6.webp" alt="Metrics" className="a-image-row" />
                        <br />
                    </div>

                    <Divider width={100} />
                    <br />

                </div>
            </WorkBase>
        </div>
    );
}