import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import { Divider } from '../components/Divider';
import GameLink from '../components/GameLink';
import { WorkCarousel } from '../components/WorkCarousel';

export const projectDetails: WorkInterface = {
    id: "civicGameLab",
    title: "Civic Game Lab",
    description: ` 
            This is a collection of games that I have developed for the Civic Games Lab.

            The Civic Games Lab is a games for social change lab that seeks to promote civic values by building games that tell South Asia’s story through play, but using its spoken, written and visual language. 

            I worked with the team to create two games for the lab.
            It's in the Water & Kartik.

            These projects were supported by the [link=SMART (Seeking Modern Applications for Real Transformation)|https://smartngo.org/] and [link=Fredrich Naumann Foundation|https://www.freiheit.org/].

        `,
    year: "2020",
    tags: ["Development", "Game", "Unity"],
    image: "/FOE.webp",
    roles: ["Game Developer", "Game Designer"],
    technologies: ["Unity", "C#", "WebGL"],
    collaborators: ["[link=Civic Games Lab (Abeer Kapoor)|https://civicgameslab.com]"],
    highlightColor: "#391040",
};

export function CivicGameLab() {
    return (
        <div className="a-main">
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    <br />
                    <br />

                    <Divider width={100} />

                    <div className="a-subtitle">It's in the Water</div>
                    <div className="a-plain-para">
                        It's in the Water is a game that takes you to the next level of fact-checking and solving a mystery with your journalistic mindset.
                        <br />
                        <br />
                        A mysterious sickness is spreading through the town of Antarjaal Nagar.

                        <br />
                        <br />
                        You must help investigative journalist Rakesh Kataria find out why this is happening – by finding answers to the several questions that arise in the course of uncovering a story.
                    </div>



                    <WorkCarousel
                        data={
                            [
                                { image: "/Water/Water1.webp", caption: "Title" },
                                { image: "/Water/Water2.webp", caption: "Character Introduction" },
                                { image: "/Water/Water3.webp", caption: "Gameplay" },
                                { image: "/Water/Water4.webp", caption: "Gameplay" },
                                { image: "/Water/Water5.webp", caption: "Gameplay" },
                                { image: "/Water/Water6.webp", caption: "Credits" },
                            ]
                        } />
                    <br />
                    <br />
                    <br />
                    <GameLink gameLink='https://civicgameslab.com/games/its-in-the-water/' gameName="It's in the Water" gameImage="/FOE.webp" width={0} color="3F3E3F" hoverColor='EB5538' />

                    <br />
                    <br />
                    <br />
                    <br />

                    <Divider width={100} />


                    <div className="a-subtitle">
                        Kartik
                    </div>

                    <div className="a-plain-para">
                    Kartik is an online playable game focusing on the risks the younger Indian generation faces and helping out an introverted teenager.
                    </div>

                    <WorkCarousel
                        data={
                            [
                                { image: "/Kartik/Kartik1.webp", caption: "Main Menu" },
                                { image: "/Kartik/Kartik2.webp", caption: "Credits" },
                                { image: "/Kartik/Kartik3.webp", caption: "Gameplay" },
                                { image: "/Kartik/Kartik4.webp", caption: "Gameplay" },
                                { image: "/Kartik/Kartik5.webp", caption: "Gameplay" },
                                { image: "/Kartik/Kartik6.webp", caption: "Gameplay" },
                                { image: "/Kartik/Kartik7.webp", caption: "Gameplay" },
                            ]
                        } />

                    <br />
                    <br />
                    <br />

                    <GameLink gameLink='https://civicgameslab.com/games/kartik/' gameName="Kartik" gameImage="/FOE.webp" width={0} color="49413A" hoverColor='994B3E' />

                    <br />
                    <br />
                    <br />
                    <br />
                    <Divider width={100} />

                    <br />
                    <br />

                </div>
            </WorkBase>
        </div>
    );
}