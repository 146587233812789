// particleOptions.ts

type ParticleOptions = {
    /* your particle options type */
};

type ParticleOptionsPerRoute = {
    [key: string]: ParticleOptions;
};

export const particleOptionsPerRoute: ParticleOptionsPerRoute = {
    "/project/bloodspeakss": {
        background: {
            color: {
                value: "#000000",
            }
        },
        fpsLimit: 120,
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "attract",
                },
                resize: true,
            },
            modes: {
                push: {
                    quantity: 1,
                },
                repulse: {
                    distance: 10000,
                    duration: 0.4,
                },
            },
        },
        particles: {
            collisions: {
                enable: true,
            },
            move: {
                direction: "none",
                enable: true,
                outModes: {
                    default: "out",
                },
                random: false,
                speed: 0.1,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                    // area: 2100,
                },
                value: 0
            },
            opacity: {
                value: { min: 0.4, max: 0.8 },
            },
            shape: {
                type: "image",
                image: {
                    src: "/splatter.webp",
                }
            },
            size: {
            value: { min: 100, max: 500 },
            }
        },
        detectRetina: true,
    },
};

export const defaultParticleOptions: ParticleOptions =
{
    background: {
        color: {
            value: "#F2F7FA",
        }
    },
    fpsLimit: 120,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: "push",
            },
            onHover: {
                enable: true,
                mode: "repulse",
            },
            // resize: true,
        },
        modes: {
            push: {
                quantity: 1,
            },
            repulse: {
                distance: 100,
                duration: 1,
            },
        },
    },
    particles: {
        color: {
            value: "#465059",
        },
        links: {
            color: "#98A0A6",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
        },
        collisions: {
            enable: true,
        },
        move: {
            direction: "none",
            enable: true,
            outModes: {
                default: "bounce",
            },
            random: false,
            speed: 0.2,
            straight: false,
        },
        number: {
            density: {
                enable: true,
                area: 1500,
            },
            value: 70,
        },
        opacity: {
            value: { min: 0.2, max: 0.4}
        },
        shape: {
            type: "circle",
        },
        size: {
            value: { min: 1, max: 5 },
        },
    },
    detectRetina: true,
};