import React from 'react';
import { ReactComponent as Cross } from '../images/cross.svg';
import NavTags from './NavTags';
import '../styles/mobile-nav.css'
import { NavLink, useLocation } from 'react-router-dom';

interface MobileNavProps {
    setIsMenuOpen: (isMenuOpen: boolean) => void;
    isMenuOpen: boolean;
}

interface NavItemProps {
    title: string;
    link: string;
    closeMenu: () => void; // New callback prop
}

export const NavItem: React.FC<NavItemProps> = ({ title, link, closeMenu }) => {
    const location = useLocation();
    const isActive = location.pathname === link;

    const handleClick = () => {
        closeMenu();
    };

    return (
        <li>
            <NavLink
                to={link}
                className={`mobile-nav-link ${isActive ? 'mobile-nav-link-active' : ''}`}
                onClick={handleClick}
            >
                {title}
            </NavLink>
        </li>
    );
};


function MobileNav({ setIsMenuOpen, isMenuOpen }: MobileNavProps) {

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <div className="mobile-nav">
            <button className="close-icon" onClick={toggleMenu}>
                <Cross className="cross-icon" />
            </button>
            <div className="mobile-nav-body">
                <ul className={`mobile-list`}>
                    <NavItem title="Work" link="/work" closeMenu={closeMenu} />
                    <NavItem title="About Me" link="/about" closeMenu={closeMenu} />
                </ul>
            </div>
        </div>
    );
};

export default MobileNav;