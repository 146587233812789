import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../styles/work-carousel.css';

export interface WorkCarouselData {
    image: string;
    caption: string;
}

export function WorkCarousel({ data }: { data: WorkCarouselData[] }) {
    return (
        <Carousel infiniteLoop={true} autoPlay={false} showThumbs={false} showStatus={false} showArrows={true} >
            {
                data.map((item) => (
                    <div>
                        <img src={item.image} alt="item.image" className="work-carousel-image" />
                        <p className="work-legend">{item.caption}</p>
                    </div>
                ))
            }
        </Carousel>
    );
}
