import '../styles/youtube.css'

interface YoutubeEmbedProps {
  embedId: string;
}

function YoutubeEmbed({ embedId }: YoutubeEmbedProps): JSX.Element {
  return (
    <div className="video-responsive">
      <iframe
        src={`https://www.youtube.com/embed/${embedId}?rel=0`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
}

export default YoutubeEmbed;