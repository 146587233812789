import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "Tarq",
    title: "Tarq",
    description: ` 
        This project is a web-based AR experience that allows users to explore the gallery space of TARQ, a contemporary art gallery in Mumbai, India. This project was made during the pandemic to allow users to experience the gallery space safely from the comfort of their homes.
        
        The gallery featured the work of [link=Sameer Kulavoor|https://www.instagram.com/sam_kulavoor/], [link=Ritika Merchant|https://www.instagram.com/rithikamerchant/] and [link=Garima Gupta|https://www.instagram.com/_.g_a_r_i_m_a._/]. All of them are amazing contemporary Indian artists.
        
        I had an amazing time working with [link=Varun Ramanna|https://www.varuns.work/] and [link=Divya Tak|https://www.divyatak.art/] to create this experience.

        The experience was created using PlayCanvas and Vectary.
        `,
    year: "2020-2021",
    tags: ["Web Dev", "AR", "Museum Installation"],
    image: "/tarq.webp",
    roles: ["Developer"],
    technologies: ["Javascript", "PlayCanvas", "AR", "Vectary"],
    collaborators: ["[link=TARQ|https://www.tarq.in/], [link= Varun Ramanna|https://www.varuns.work/], [link=Divya Tak|https://www.divyatak.art/]"],
    highlightColor: "#60151F",

};


export function Tarq() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />
                    <div className="a-subtitle">
                        Gallery Behind the Scenes
                    </div>
                    <br />
                    <br />
                    <WorkCarousel
                        data={
                            [
                                {image: "/Tarq/Tarq1.webp" , caption: "Garima Landing Page" },
                                {image: "/Tarq/Tarq2.webp" , caption: "Sammer Landing Page" },
                                {image: "/Tarq/Tarq3.webp" , caption: "Ritika Landing Page" },
                                {image: "/Tarq/Tarq4.webp" , caption: "Art in AR" },
                                {image: "/Tarq/Tarq5.webp" , caption: "Exibition in Person" },
                                {image: "/Tarq/Tarq6.webp" , caption: "Exibition in Person" },
                                {image: "/Tarq/Tarq8.webp" , caption: "Vectary Test" },
                            ]
                        } />

                    <br />
                    <br />
                    <br />
                    <Divider width={100} />
                    <br />
                    <br />
                    <br />
                </div>
            </WorkBase>
        </div>
    );
}