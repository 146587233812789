import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import { Divider } from '../components/Divider';
import GameLink from '../components/GameLink';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "chronoline",
    title: "Chronoline",
    description: `
            A trivia game that tests your knowledge for the timeline of events. Arrange the oncoming events on the timeline.

            I have always been interested in trivia/quiz based games and wanted to create one myself. There were 4 decks for this game. The decks were created from various sources on the internet, and the India deck was curated by [link=Mohammed Fahad|https://twitter.com/mohdfahad42].

            This game was also an opportunity for me to learn and hone my skills in Art and UI design. After a lot of iterations, I think I was able to create something which looked cohesive and was fun to play. The art was generated using [link=Midjourney|https://midjourney.com/]. Including the landing page and the card deck backgrounds.
        `,
    year: "2023",
    tags: ["Solo Creator", "Game", "Unity"],
    image: "/chronoline.webp",
    roles: ["Game Developer", "Game Designer"],
    technologies: ["Unity", "C#", "Midjourney"],
    collaborators: ["India Deck by [link=Mohammed Fahad|https://twitter.com/mohdfahad42]"],
    highlightColor: "#391040",
};


export function Choronoline() {
    return (
        <div className="a-main">
            <WorkBase project={projectDetails} >
                {/* <img src={projectDetails.image} alt="Blood Speaks" className="a-image" max-width="30px" /> */}
                <div className="a-body">
                    <br />
                    <br />

                    <div className='a-flex-row' style={{ maxWidth: '400px' }}>
                        <GameLink gameLink='https://charutak.itch.io/chronoline' gameName="" gameImage="/chronoline.webp" width={0}
                            color="391040" hoverColor='9C4D7C' overrideText='Play Web' />

                        <GameLink gameLink='https://apps.apple.com/in/app/chronoline-history-trivia/id6479247074' gameName="" gameImage="/chronoline.webp" width={0}
                            color="391040" hoverColor='9C4D7C' overrideText='Play Ios' />
                    </div>
                    <br />
                    <br />
                    <Divider width={100} />

                    <div className="a-plain-para">
                        Here are some of the details and behind the scenes of the making of this game.
                    </div>

                    <div className="a-subtitle">
                        Design
                    </div>

                    <div className="a-plain-para">
                        Here are some of the early designs for the game and the final design.
                    </div>

                    <WorkCarousel data={
                        [
                            { image: "/Frame_1.webp", caption: "Main Menu iteration #1" },
                            { image: "/Frame_2.webp", caption: "Game Screen iteration #1" },
                            { image: "/Frame_3.webp", caption: "Main Menu iteration #2" },
                            { image: "/Frame_4.webp", caption: "Game Screen iteration #2" },
                            { image: "/1.webp", caption: "Main Menu Final" },
                            { image: "/2.webp", caption: "Game Screen Final" },
                        ]
                    } />

                    <Divider width={100} />
                    <br />
                    <br />
                    <br />

                    <div className="a-subtitle">
                        Art
                    </div>

                    <div className="a-plain-para">
                        I also iterated on the main menu image using Midjourney. Here are some of the iterations.
                    </div>

                    <WorkCarousel data={
                        [
                            { image: "/Timeline/image7.webp", caption: "Rejected" },
                            { image: "/Timeline/image8.webp", caption: "Rejected" },
                            { image: "/Timeline/image11.webp", caption: "Rejected" },
                            { image: "/Timeline/image16.webp", caption: "Rejected" },
                            { image: "/Timeline/image21.webp", caption: "Rejected" },
                            { image: "/Timeline/image25.webp", caption: "Rejected" },
                            { image: "/Timeline/image29.webp", caption: "Rejected" },
                            { image: "/Timeline/image15.webp", caption: "Final" },
                        ]
                    } />

                    <div className="a-plain-para">
                        The game was designed to be a trivia game that tests your knowledge for the timeline of events.
                        The player has to arrange a variety of events in the correct order.
                        There are 4 Decks of categories to choose from.
                    </div>

                    <div className="a-subtitle">
                        Decks
                    </div>

                    <div className="a-plain-para">
                        I created these 4 decks for this game.  <br />
                    </div>

                    <div className="a-plain-list">
                        <div className='link-list' style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <a className="a-lin" >
                                <img src="/Timeline/India.webp" alt="Tribeca" className='a-nonlink-image' />
                                <div className='a-span-bold'>India</div>
                            </a>
                            <a className="a-lik">
                                <img src="/Timeline/Movie.webp" alt="Tribeca" className='a-nonlink-image' />
                                <div className='a-span-bold'>Movies</div>
                            </a>
                            <a className="a-lik">
                                <img src="/Timeline/Person.webp" alt="Tribeca" className='a-nonlink-image' />
                                <div className='a-span-bold'>People</div>
                            </a>
                            <a className="a-lik">
                                <img src="/Timeline/Science.webp" alt="Tribeca" className='a-nonlink-image' />
                                <div className='a-span-bold'>Science</div>
                            </a>
                        </div>
                    </div>

                    <Divider width={100} />

                    <div className="a-plain-para">
                        Hope you play the game and enjoy it. <br />
                    </div>
                    <div className='a-flex-row' style={{ maxWidth: '400px' }}>
                        <GameLink gameLink='https://charutak.itch.io/chronoline' gameName="" gameImage="/chronoline.webp" width={0}
                            color="391040" hoverColor='9C4D7C' overrideText='Play Web' />

                        <GameLink gameLink='https://apps.apple.com/in/app/chronoline-history-trivia/id6479247074' gameName="" gameImage="/chronoline.webp" width={0}
                            color="391040" hoverColor='9C4D7C' overrideText='Play Ios' />
                    </div>
                    <br />
                    <br />
                    <br />
                </div>



            </WorkBase>
        </div>
    );
}