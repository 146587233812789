import { ContactIcons } from "../components/contacticons";
import { WorkInterface } from "../pages/Work";
import '../styles/custom-styles.css'


interface WorkBaseProps {
    project: WorkInterface;
    children: React.ReactNode;
}

function setCSSVariable(name: string, value: string) {
    document.documentElement.style.setProperty(name, value);
}

export function WorkBase({ project, children }: WorkBaseProps) {


    function renderDescription(description: string) {
        const parts = description.split('[link=');

        // If no parts, just return the description
        if (parts.length === 1) return description;

        return parts.map((part, index) => {
            if (index === 0) return part;

            const [nameAndUrl, afterName] = part.split(']');
            const [name, url] = nameAndUrl.split('|');
            return (
                <>

                    <a href={url} key={index} className="a-description-link" target="_blank" rel="noreferrer"
                    >{name}</a>
                    {afterName}
                </>
            );
        });
    }

    const inlineStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${project.image})`,
    };

    var roleString = "";
    var roles = project.roles;
    if (roles) {
        roleString = roles.join(", ");
    }

    var technologyString = "";
    var technologies = project.technologies;
    if (technologies) {
        technologyString = technologies.join(", ");
    }

    var collaboratorString = "";
    var collaborators = project.collaborators;
    if (collaborators) {
        collaboratorString = collaborators.join(", ");
    }

    if (project.highlightColor) {
        setCSSVariable("--project-highlight-color", project.highlightColor);
    }

    return (
        <div className="a-base">
            <div className="a-title" style={inlineStyle}>
                {project.title}
            </div>

            <div className="a-top">
                <div className="a-left">
                    <div className="a-description">
                        {renderDescription(project.description)}
                    </div>
                </div>
                <div className="a-vertical-divider"></div>
                <div className="a-right">
                    <div className="a-details-base">
                        <div className="a-detail">
                            <div className="a-details-heading">
                                Roles
                            </div>
                            <div className="a-details-detail">
                                {roleString}
                            </div>
                        </div>
                        <div className="a-detail">
                            <div className="a-details-heading">
                                Year
                            </div>
                            <div className="a-details-detail">
                                {project.year}
                            </div>
                        </div>
                        <div className="a-detail">
                            <div className="a-details-heading">
                                Tools/Technology
                            </div>
                            <div className="a-details-detail">
                                {technologyString}
                            </div>
                        </div>
                        <div className="a-detail">
                            {renderDescription(collaboratorString) && (
                                <>
                                    <div className="a-details-heading">
                                        Collaborators
                                    </div>
                                    <div className="a-details-detail">
                                        {renderDescription(collaboratorString)}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                </div>
            </div>
            <br />
            {children}
            <ContactIcons />
        </div>
    );
}