import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import { WorkCarousel } from '../components/WorkCarousel';

export const projectDetails: WorkInterface = {
    id: "BloodSpeaks",
    title: "Maya: The Birth of a Superhero",
    description: ` 
        An immersive VR experience follows Maya, a typical 21st Century girl, who becomes a superhero with powers rooted in menstruation.
    
        I thoroughly enjoyed working on this project. The topic is deeply personal to me, and creating a piece that genuinely reflects the story was incredibly fulfilling.
    
        My primary roles were in gameplay programming and mechanics design, focusing on the integration of gameplay, art, animation, sound, and story.
    
        The team was spread across the globe, each truly inspiring to work with. I worked closely with [link=CJ Clarke|http://www.cjclarke.com/info], [link=Alap Parikh|https://www.alapparikh.com/], [link=Amaury Campion|http://studio-geppetto.com/], [link=Poulomi Basu|http://www.poulomibasu.com/about], and [link=Tim Harrison|https://www.aumeta.com/].
        `,
    year: "2022-2023",
    tags: ["Development", "Game Design", "Unreal", "VR"],
    image: "/maya.webp",
    roles: ["Gameplay Programmer", "Game Designer"],
    technologies: ["Unreal", "Houdini", "VR", "Quest"],
    collaborators: ["[link=JAPC Studios (London, UK)|https://www.linkedin.com/company/just-another-production-company-ltd-japc/about/] , [link=Floréal Films (Paris, France)], [link=CJ Clarke|http://www.cjclarke.com/info], [link=Alap Parikh|https://www.alapparikh.com/], [link=Amaury Campion|http://studio-geppetto.com/], [link=Poulomi Basu|http://www.poulomibasu.com/about], and [link=Tim Harrison|https://www.aumeta.com/]." ],
    highlightColor: "#60151F",

};

export function BloodSpeaks() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                {/* <img src={projectDetails.image} alt="Blood Speaks" className="a-image" max-width="30px" /> */}
                <div className="a-body">
                    <YoutubeEmbed embedId="KU4_Bb2-krw" />
                    <div className="a-plain-para">
                        Created and co-directed by renowned artist and women rights activist, <span className='a-span-bold'>Poulomi Basu</span> and co-directed by <span className='a-span-bold'>CJ Clarke</span>, ‘Maya: The Birth of a Superhero’ stars <span className='a-span-hold'>Indira Varma </span> (Game of Thrones), <span className='a-span-bold'>Charithra Chandran </span>(Bridgerton) and <span className='a-span-bold'>Florrie Antoniou. </span>
                        <br />
                        <br />
                        The experience recently received a ‘Special Jury Mention Award’ at the <span className='a-span-bold'>Tribeca Film Festival</span> in the New Voices Immersive Competition. With the arrival of her first period, Maya’s world is turned upside down as she is confronted by the restrictive traditions of her conservative family, and a world of hidden shame, stigma and taboo to be found in contemporary London. She must overcome her own shame and fear to find her inner strength and true super-powers.
                        <br />
                        <br />

                    </div>
                    <br />

                    <Divider width={100} />
                     <div className="a-plain-para">
                        Some stills from the experience:
                        <br />
                    </div>
  
                    <WorkCarousel 
                    data={
                        [
                            { image: "/BloodSpeaks/Bedroom.webp", caption: "Maya's Bedroom" },
                            { image: "/BloodSpeaks/Buildings.webp", caption: "London Buildings" },
                            { image: "/BloodSpeaks/CampFire.webp", caption: "A Scary Night" },
                            { image: "/BloodSpeaks/Lava.webp", caption: "Cave" },
                            { image: "/BloodSpeaks/Monster.webp", caption: "The Monster" },
                            { image: "/BloodSpeaks/Tree.webp", caption: "Rebirth" },
                            { image: "/BloodSpeaks/Village.webp", caption: "Village" },
                        ]
                    }/>
                    <br />
                    <br />
                    <br />
                    <Divider width={100} />

                    <div className="a-plain-list">
                        <span className='a-span-bold'>Press / Media </span>  <br />
                        <div className='link-list'>
                            <a className="a-link"
                                href="https://tribecafilm.com/films/maya-the-birth-chapter-1-2023" target="_blank" >
                                <img src="/tribeca.webp" alt="Tribeca" className='a-link-image' />
                            </a>
                            <a className="a-link"
                                href="https://www.londondesignfestival.com/activities/maya-the-birth-of-a-superhero" target="_blank" >
                                <img src="/va.webp" alt="London Design Festival" className='a-link-image' />
                            </a>
                            <a className="a-link"
                                href="https://www.youtube.com/watch?v=_G4d8MYQB3c" target="_blank" >
                                <img src="/music.webp" alt="Youtube" className='a-link-image' />
                            </a>
                        </div>
                    </div>

                </div>
            </WorkBase>
        </div>
    );
}