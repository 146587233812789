import React from "react";
import { Link } from "react-router-dom";
import '../styles/work.css';

interface WorkPreviewProps {
    id: string;
    title: string;
    description: string;
    date: string;
    tags: string[];
    image: string;
}

function WorkPreview({ id, title, description, date, tags, image }: WorkPreviewProps) {
    return (
        <div className="project-preview">
            <Link to={`/work/${id.toLowerCase()}`} className="project-link">
                <img src={image} alt="Maya" className="project-image" />
                <div className="project-preview-hoverover">
                    <div className="project-title">
                        {title}
                    </div>
                    <div className="project-preview-detail">
                        <div className="project-tags">
                            {GetTagString(tags)}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

function GetTagString(tags: string[]) {
    var tagString = "";
    if (tags) {
        tagString = tags.join(" | ");
    }
    return tagString;
}

export default WorkPreview;