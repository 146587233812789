import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "Synesthesia",
    title: "Synesthesia",
    description: ` 
            Synesthesia is a short musical experience that I created as a part of the [link=Unreal WCP|https://www.unrealengine.com/en-US] fellowship. This fellowship was supported by [link=ASIFA India|https://www.asifaindia.org/].
            
            [link=WCP (Women Creators Program)|https://www.asifaindia.org/WCP.html] is an annual creator program, where they select a few females to create a short film of their choice and support them along the process.

            I created this video where the environment and the music are in sync with each other. The experience is a short 2 minute experience that immerses the user in a world of music and visuals.

            I learnt a lot about movie production and Unreal Engine through this experience. I also made a lot of friends through this experience and it was a lot of fun.
        `,
    year: "2022",
    tags: ["Video Production", "Solo Project", "Unreal"],
    image: "/wcp.webp",
    roles: ["Programmer", "Producer"],
    technologies: ["Unreal", "Blueprint"],
    collaborators: ["[link=Unreal WCP|https://www.unrealengine.com/en-US]"],
    highlightColor: "#60151F",

};


export function UnrealFellowship() {
    return (
        <div>
            <WorkBase project={projectDetails} >
            <div className="a-body">
                    <br />
                    <br />
                    <Divider width={100} />

                    <div className="a-plain-para">
                        Here is the video that I created.
                    </div>
                    <YoutubeEmbed embedId="N-8Ad6ZM5v0" />

                    <br />
                    <br />
                    <Divider width={100} />
                    <br />
                   
                    <br />
                </div>


            </WorkBase>
        </div>
    );
}