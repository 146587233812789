import { WorkBase } from './WorkBase';
import { WorkInterface } from '../pages/Work';
import YoutubeEmbed from '../components/Youtube';
import { Divider } from '../components/Divider';
import { WorkCarousel } from '../components/WorkCarousel';


export const projectDetails: WorkInterface = {
    id: "Incommon",
    title: "Incommon",
    description: ` 
    A new type of VR experience where the environment becomes part of the stories people share with each other.
    The participants enter a specially designed sound-proof booth on-site at two different physical locations and wear the VR headsets.
    
    The participants can see each other in VR and hear each other through the headphones. As they talk and answer personal questions, the environment around them changes to reflect the stories they share.

    I worked as the developer for this multiplayer VR expereince. 
    
    This project is created by [link=Lotte Louise de Jong|http://lottelouise.nl/about.html], [link=Noémi Biró|https://v2.nl/people/noemi-biro], [link=Ryan Cherewaty |http://www.ryancherewaty.com/] in collaboration with [link=TU Delft|https://www.tudelft.nl/en/ide/].
    `,
    year: "2023",
    tags: ["Development", "VR", "Unreal"],
    image: "/Incommon/Incommon1.webp",
    roles: ["Gameplay Programmer"],
    technologies: ["Unreal", "Blueprint", "VR", "Quest"],
    collaborators: ["[link=Lotte Louise de Jong|http://lottelouise.nl/about.html], [link=Noémi Biró|https://v2.nl/people/noemi-biro], [link=Ryan Cherewaty |http://www.ryancherewaty.com/]"],
    highlightColor: "#60151F",

};


export function Incommon() {
    return (
        <div>
            <WorkBase project={projectDetails} >
                <div className="a-body">
                    <Divider width={100} />
                    <div className="a-plain-para">
                        Some behind the scenes images:
                        <br />
                    </div>

                    <WorkCarousel
                        data={
                            [
                                { image: "/Incommon/Incommon1.webp", caption: "Gameplay" },
                                { image: "/Incommon/Incommon3.webp", caption: "Gameplay" },
                                { image: "/Incommon/Incommon4.webp", caption: "Gameplay" },
                                { image: "/Incommon/Incommon2.webp", caption: "Gameplay" },
                                { image: "/Incommon/Incommon5.webp", caption: "Gameplay" },
                                { image: "/Incommon/Incommon6.webp", caption: "Gameplay" },
                            ]
                        } />
                    <br />
                    <br />
                    <Divider width={100} />

                    <br />
                    <br />
                 
                </div>
            </WorkBase>
        </div>
    );
}